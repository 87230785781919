@use 'colors';
@use 'mixin';

h2 {
  width: 100%;
  text-align: center;
  margin: 40px 0;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 900;
}

h3 {
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: colors.$my-red;
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}
