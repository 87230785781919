@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Container {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
}

.Inside {
  @extend .responsive-width;
  @extend .bsbb;
  margin: 20px 0 20px 0;
  padding: 10px;
  h2 {
    margin-top: 0;
  }
}

.UlContacts {
  @extend .display-flex;
  @extend .flex-direction-column;

  @media screen and (min-width: 768px) {
    @include mixin.flex-row;
  }

  > li {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    @extend .bsbb;

    @media screen and (min-width: 768px) {
      &:first-of-type {
        padding-right: 10px;
      }
      &:last-of-type {
        padding-left: 10px;
      }
    }
  }
}

.Privacy {
  font-size: 14px;
  a {
    color: colors.$my-red;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
  }
  margin-top: 10px;
}

.Button {
  margin-top: 20px;
  @extend .display-flex;
  @extend .flex-center;
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  padding: 20px;
  @extend .bsbb;
  height: 60px;
  font-weight: 500;
  border: 1px solid colors.$my-red;
  background: linear-gradient(colors.$white, colors.$white 50%, colors.$my-red 50%, colors.$my-red);
  background-size: 100% 200%;
  &[disabled] {
    @include mixin.opacity(0.5);
    pointer-events: none;
  }
  &:hover {
    color: colors.$white;
    background-position: 100% 100%;
  }
}

.Message {
  padding-top: 20px;
  font-weight: 600;
  color: colors.$my-red;
}
