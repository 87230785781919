@use 'colors';
@use 'mixin';

label {
  @extend .display-flex;
  @extend .flex-direction-column;
  text-transform: uppercase;
  color: colors.$my-red;
  font-weight: 500;
  font-size: 14px;
  input,
  textarea,
  select {
    color: colors.$grey-900;
    margin-top: 10px;
    @extend .bsbb;
    border: 1px solid colors.$grey;
  }
  input,
  select {
    height: 42px;
  }
  textarea {
    padding: 10px;
    min-height: 150px;
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}
