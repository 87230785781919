@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.ScrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  @extend .display-flex;
  @extend .flex-center;
  background: colors.$my-red;
  color: colors.$white;
  @include mixin.border-radius(50%);
  z-index: colors.$zi-scroll-top;
}
