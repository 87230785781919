@use '../../../styles/colors';
@use '../../../styles/mixin';

.Box {
  position: relative;
  height: 400px;
  width: 100%;
  @extend .display-flex;
  @extend .flex-end;
  text-decoration: none;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    z-index: 2;
    @extend .trstn;
    background: transparent;
  }
  &:hover {
    &:after {
      @extend .trstn;
      background: colors.$my-red;
    }
  }
  img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @extend .image-fit;
  }
}

.Content {
  background: rgba(colors.$black, 0.5);
  color: colors.$white;
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .bsbb;
  padding: 20px 20px 25px 20px;
  width: 100%;
  z-index: 1;
  > li {
    &:first-of-type {
      font-weight: 700;
      display: block;
      margin-bottom: 20px;
      font-size: 24px;
      text-transform: uppercase;
      word-break: break-word;
      width: 100%;
    }
    &:last-of-type {
      @include mixin.calc('width', '100% - 40px');
    }
  }
}

.Icon {
  z-index: 2;
  position: absolute;
  bottom: 20px;
  right: 10px;
  i {
    color: colors.$white;
    background: colors.$my-red;
    @include mixin.border-radius(50%);
    @extend .display-flex;
    @extend .flex-center;
    width: 40px;
    height: 40px;
  }
}
