@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Payoff {
  width: 100%;
  background: url(../../../../assets/pistola.jpg) no-repeat center center;
  background-size: cover;
  @extend .display-flex;
  @extend .flex-center;
  padding: 20px;
  @extend .bsbb;
}

.Inside {
  width: 100%;
  height: 100%;
  background: rgba(colors.$my-dark, 0.5);
  @extend .bsbb;
  padding: 20px;
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  font-weight: 700;
  color: colors.$white;
  font-size: 32px;
  text-align: center;
}
