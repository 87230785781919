@use '../../../styles/colors';
@use '../../../styles/mixin';

.Paragraph {
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  span {
    width: 100%;
    text-align: left;
  }
}
