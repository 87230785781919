@use '../../../../../styles/colors';
@use '../../../../../styles/mixin';

.Container {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
}

.UlSocial {
  @extend .display-flex;
  @extend .flex-direction-row;
  li {
    margin: 0 20px;
    a {
      font-size: 18px;
      text-decoration: none;
      &:hover {
        color: colors.$my-red;
      }
    }
  }
}
