@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  @extend .bsbb;
  padding: 10px;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-justify-between;
  background: colors.$white;
  color: colors.$my-dark;
  z-index: colors.$zi-nav;
  button {
    margin-top: 4px;
  }
}

.Logo {
  display: block;
  height: 50px;
  img {
    height: 100%;
  }
}

.Locate {
  height: 40px;
  width: 40px;
  font-size: 24px;
  @extend .display-flex;
  @extend .flex-center;
  color: colors.$my-red;
  text-decoration: none;
}

.BlackBar {
  background: colors.$my-dark;
  width: 100%;
  position: fixed;
  top: 60px;
  left: 0;
  height: 20px;
  z-index: colors.$zi-blackbar;
  text-align: center;
  @extend .display-flex;
  @extend .flex-center;
  a {
    text-decoration: none;
    height: 20px;
    @extend .display-flex;
    @extend .flex-center;
    img {
      height: 10px;
    }
  }
}

.Menu {
  z-index: colors.$zi-menu;
  top: 60px;
  position: fixed;
  left: -100%;
  width: 100%;
  height: 100%;
  background: colors.$my-red url(../../../../assets/logo_white_fade.svg) no-repeat
    bottom right;
  background-size: contain;
  @extend .trstn;
}

.MenuOpen {
  @extend .trstn;
  left: 0;
  padding: 10px;
  @extend .bsbb;
}

.UlMenu {
  margin-top: 20px;
  margin-left: 10px;
  li {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
}

.NavLink {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  color: colors.$white;
  text-decoration: none;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: colors.$white;
    bottom: -5px;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    &:before {
      transform-origin: left;
      transform: scaleX(1);
    }
  }
}

.NavActive {
  @extend .NavLink;
  &:before {
    transform-origin: left;
    transform: scaleX(1);
  }
}
