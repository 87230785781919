@use '../../../styles/colors';
@use '../../../styles/mixin';

.LoaderBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: colors.$white;
  z-index: colors.$zi-page-loader;
  @extend .display-flex;
  @extend .flex-center;
  background: rgba(colors.$my-dark, 0.9);
  @extend .backdrop;
}

.Loader {
  width: 60px;
  height: 60px;
  border: 2px solid colors.$my-red;
  border-radius: 50%;
  border-top: 2px solid transparent;
  @include mixin.border-radius(50%);
  animation: rotation 3s linear infinite;
}

.Logo {
  position: absolute;
  z-index: 1;
  font-size: 1.5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @extend .display-flex;
  @extend .flex-center;
  img {
    width: 30px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Message {
  position: fixed;
  bottom: 0;
  left: 0;
  @extend .bsbb;
  padding: 10%;
  width: 100%;
  text-align: center;
  color: colors.$my-red;
  font-weight: 500;
  > div {
    width: 100%;
    color: colors.$my-red;
    font-weight: 500;
    span {
      width: 100%;
      text-align: center;
    }
  }
}
