@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Footer {
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding: 40px 0;
  background: colors.$my-dark;
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .flex-center;
  color: colors.$white;
  white-space: nowrap;
  a {
    font-size: 12px;
    color: colors.$white;
    text-decoration: underline;
  }
  img {
    width: 100px;
  }
}
